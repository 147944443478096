<template>
    <div class="stepsdemo-content">
        <div class="grid p-fluid">
            <div class="col-12">
                <div class="grid">
                    <div class="col-12">
                        <div class="card mb-0">
                            <h5 class="text-xl mt-2">Title: <span class="text-500 font-medium">{{formData.title ? ' '+formData.title : ' -'}}</span></h5>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="card mb-0">
                            <span class="block font-semibold mb-2">Starting Point</span>
                            <div class="flex flex-wrap">
                                <div class="flex align-items-center justify-content-center m-2"><span class="font-bold">Country:&nbsp;</span><span> {{formData.starting_point.country ? formData.starting_point.country : ' -'}}</span></div>
                                <div class="flex align-items-center justify-content-center m-2"><span class="font-bold">Division:&nbsp;</span><span> {{formData.starting_point.division ? formData.starting_point.division : ' -'}}</span></div>
                                <div class="flex align-items-center justify-content-center m-2"><span class="font-bold">District:&nbsp;</span><span> {{formData.starting_point.district ? formData.starting_point.district : ' -'}}</span></div>
                                <div class="flex align-items-center justify-content-center m-2"><span class="font-bold">Thana:&nbsp;</span><span> {{formData.starting_point.thana ? formData.starting_point.thana : ' -'}}</span></div>
                                <div class="flex align-items-center justify-content-center m-2"><span class="font-bold">Address:&nbsp;</span><span> {{formData.starting_point.line ? formData.starting_point.line : ' -'}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="card mb-0">
                            <span class="block font-semibold mb-2">Destination</span>
                            <div class="flex flex-wrap">
                                <div class="flex align-items-center justify-content-center m-2"><span class="font-bold">Country:&nbsp;</span><span> {{formData.destination.country ? formData.destination.country : ' -'}}</span></div>
                                <div class="flex align-items-center justify-content-center m-2"><span class="font-bold">Division:&nbsp;</span><span> {{formData.destination.division ? formData.destination.division : ' -'}}</span></div>
                                <div class="flex align-items-center justify-content-center m-2"><span class="font-bold">District:&nbsp;</span><span> {{formData.destination.district ? formData.destination.district : ' -'}}</span></div>
                                <div class="flex align-items-center justify-content-center m-2"><span class="font-bold">Thana:&nbsp;</span><span> {{formData.destination.thana ? formData.destination.thana : ' -'}}</span></div>
                                <div class="flex align-items-center justify-content-center m-2"><span class="font-bold">Address:&nbsp;</span><span> {{formData.destination.line ? formData.destination.line : ' -'}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-4">
                        <div class="card mb-0">
                            <p><span class="font-bold">Total Seat: </span><span>{{formData.seat_total ? ' '+formData.seat_total : ' -'}}</span></p>
                            <p><span class="font-bold">Booked Seat: </span><span>{{(formData.seat_booked >= 0) ? ' '+formData.seat_booked : ' -'}}</span></p>
                            <p><span class="font-bold">Refund Percentage: </span><span>{{(formData.refund_percentage >= 0) ? ' '+formData.refund_percentage +'%' : ' -'}}</span></p>
                            <p><span class="font-bold">Manager ID: </span><span>{{(formData.manager_id >= 0) ? ' '+formData.manager_id : ' -'}}</span></p>
                        </div>
                    </div>
                    <div class="col-12 md:col-4">
                        <div class="card mb-0">
                            <p><span class="font-bold">Start Date: </span><span>{{formatDate(start_date) ? ' '+ formatDate(start_date) : ' -'}}</span></p>
                            <p><span class="font-bold">End Date: </span><span>{{formatDate(end_date) ? ' '+ formatDate(end_date) : ' -'}}</span></p>
                            <p><span class="font-bold">Booking Deadline: </span><span>{{formatDate(booking_deadline) ? ' '+ formatDate(booking_deadline) : ' -'}}</span></p>
                            <p><span class="font-bold">Cancellation Deadline: </span><span>{{cancellation_deadline ? ' '+ formatDate(cancellation_deadline) : ' -'}}</span></p>
                        </div>
                    </div>
                    <div class="col-12 md:col-4">
                        <div class="card mb-0">
                            <p><span class="font-bold">Number of Days: </span><span>{{formData.day ? ' '+formData.day : ' 0'}} day(s)</span></p>
                            <p><span class="font-bold">Number of Nights: </span><span>{{formData.night ? ' '+formData.night : ' 0'}} day(s)</span></p>
                            <p><span class="font-bold">Number of Meals: </span><span>{{formData.meals ? ' '+formData.meals : ' 0'}}</span></p>
                            <p><span class="font-bold">T-Shirt: </span><span v-if="formData.tshirt == 'true'">Yes</span><span v-else-if="formData.tshirt == 'false'">No</span></p>
                        </div>
                    </div>
                    <div class="col-6 md:col-3">
                        <div class="card mb-0">
                            <div class="flex justify-content-between">
                                <div>
                                    <span class="block font-medium mb-2">Price Adults</span>
                                    <div>
                                        <span class="text-900 font-medium text-xl">{{formData.price_adults ? '৳ '+formData.price_adults : ' -'}}</span>
                                        <span class="text-500"> per person</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 md:col-3">
                        <div class="card mb-0">
                            <div class="flex justify-content-between">
                                <div>
                                    <span class="block font-medium mb-2">Discount Price Adults</span>
                                    <div>
                                        <span class="text-900 font-medium text-xl">{{formData.discount_adults ? '৳ '+formData.discount_adults : ' -'}}</span>
                                        <span class="text-500"> per person</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 md:col-3">
                        <div class="card mb-0">
                            <div class="flex justify-content-between">
                                <div>
                                    <span class="block font-medium mb-2">Price Children</span>
                                    <div>
                                        <span class="text-900 font-medium text-xl">{{formData.price_children ? '৳ '+formData.price_children : ' -'}}</span>
                                        <span class="text-500"> per person</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 md:col-3">
                        <div class="card mb-0">
                            <div class="flex justify-content-between">
                                <div>
                                    <span class="block font-medium mb-2">Discount Price Adults</span>
                                    <div>
                                        <span class="text-900 font-medium text-xl">{{formData.discount_children ? '৳ '+formData.discount_children : ' -'}}</span>
                                        <span class="text-500"> per person</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field col-12 card m-1 p-4">
                        <h5>Schedule:</h5>
                        <div class="grid" v-for="(item, index) of formData.schedule" :key="index">
                            <div class="col-3 lg:col-1">
                                <p><span class="font-bold">Day: </span><span>{{item.day ? ' '+item.day : ' -'}}</span></p>
                            </div>
                            <div class="col-9 lg:col-11">
                                <div class="grid" v-for="(activity, index)  in item.activities" :key="index">
                                    <div class="col-12 lg:col-2">
                                        <p v-if="typeof(activity.time) == 'object'"><span class="font-bold">Time: </span><span>{{formatTime(activity.time) ? ' '+ formatTime(activity.time) : ' -'}}</span></p>
                                        <p v-else><span class="font-bold">Time: </span><span>{{activity.time ? ' '+ activity.time : ' -'}}</span></p>
                                    </div>
                                    <div class="col-12 lg:col-3">
                                        <p><span class="font-bold">Title: </span><span>{{activity.title ? ' '+activity.title : ' -'}}</span></p>
                                    </div>
                                    <div class="col-12 lg:col-7">
                                        <p><span class="font-bold">Description: </span><span>{{activity.description ? ' '+activity.description : ' -'}}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>                                
                    </div>
                    <div class="col-12 lg:col-3">
                        <div class="card mb-0">
                            <h5>Places</h5>
                            <div v-for="(item, index) in formData.places" :key="index">
                                <p>{{item ? '- '+item : ' -'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-3">
                        <div class="card mb-0">
                            <h5>Includes</h5>
                            <div v-for="(item, index) in formData.included" :key="index">
                                <p>{{item ? '- '+item : ' -'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-3">
                        <div class="card mb-0">
                            <h5>Excludes</h5>
                            <div v-for="(item, index) in formData.excluded" :key="index">
                                <p>{{item ? '- '+item : ' -'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-3">
                        <div class="card mb-0">
                            <h5>Prerequisites</h5>
                            <div v-for="(item, index) in formData.prerequisites" :key="index">
                                <p>{{item ? '- '+item : ' -'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="field col-12 card m-1 p-4">
                        <h5>Description</h5>
                        <p v-if="formData.description"><span v-html="formData.description"></span></p>
                        <p v-else> -</p>
                    </div>
                    <div class="field col-12 card m-1 p-4">
                        <h5>Tour Policy</h5>
                        <p v-if="formData.tour_policy"><span v-html="formData.tour_policy"></span></p>
                        <p v-else> -</p>
                    </div>
                    <div class="field col-12 card m-1 p-4">
                        <h5>Cancellation Policy</h5>
                        <p v-if="formData.cancellation_policy"><span v-html="formData.cancellation_policy"></span></p>
                        <p v-else> -</p>
                    </div>
                    <div class="field col-12 card m-1 p-4">
                        <h5>Children Policy</h5>
                        <p v-if="formData.children_policy"><span v-html="formData.children_policy"></span></p>
                        <p v-else> -</p>
                    </div>
                    <div class="col-12 card">
                        <h5>Pictures</h5>
                        <div class="grid">
                            <div class="col-12 md:col-6 lg:col-3" v-if="formData.first_image">
                                <Image :src="formData.first_image.src"  alt="Image" imageClass="w-full" preview />
                            </div>
                            <div class="col-12 md:col-6 lg:col-3" v-if="formData.second_image">
                                <Image :src="formData.second_image.src"  alt="Image" imageClass="w-full" preview />
                            </div>
                            <div class="col-12 md:col-6 lg:col-3" v-if="formData.third_image">
                                <Image :src="formData.third_image.src"  alt="Image" imageClass="w-full" preview />
                            </div>
                            <div class="col-12 md:col-6 lg:col-3" v-if="formData.fourth_image">
                                <Image :src="formData.fourth_image.src"  alt="Image" imageClass="w-full" preview />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-content-between flex-wrap mb-4">
            <div class="flex align-items-center justify-content-center">
                <Button label="Back" @click="prevPage()" icon="pi pi-angle-left" class="w-full px-6"></Button>
            </div>
            <div class="flex align-items-center justify-content-center">
                <Button label="Create Tour" :loading="loading" @click="complete()" icon="pi pi-check" icon-pos="right" class="p-button-success w-full md:px-6 px-4"></Button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, toRef } from 'vue';
import moment from 'moment';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Axios from 'axios';

export default {
    props: {
        formData: Object
    },
    setup(props){
        const formData = toRef(props, 'formData');

        const router = useRouter();
        const toast = useToast();
        const loading = ref(false);

        const start_date = ref();
        const end_date = ref();
        const booking_deadline = ref();
        const cancellation_deadline = ref();
        console.log(formData.value);

        start_date.value = moment(formData.value.start_date).format("YYYY-MM-DD");
        end_date.value = moment(formData.value.end_date).format("YYYY-MM-DD");
        booking_deadline.value = moment(formData.value.booking_deadline).format("YYYY-MM-DD");
        cancellation_deadline.value = moment(formData.value.cancellation_deadline).format("YYYY-MM-DD");

        const formatDate = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY");
        };

        const formatTime = (rawTime) => {
            return moment(rawTime).format("hh A");
        };

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        };
        
        const complete = () => {
            loading.value = true;
            const formData1 = new FormData();

            if (formData.value.tshirt == 'true') {
                formData.value.tshirt = 1;
            }else if (formData.value.tshirt == 'false') {
                formData.value.tshirt = 0;
            }

            formData1.append("title", formData.value.title);
            formData1.append("first_image", formData.value.first_image);
            if(formData.value.second_image != null){
                formData1.append("second_image", formData.value.second_image);
            }
            if(formData.value.third_image != null){
                formData1.append("third_image", formData.value.third_image);
            }
            if(formData.value.fourth_image != null){
                formData1.append("fourth_image", formData.value.fourth_image);
            }
            formData1.append("seat_total", formData.value.seat_total);
            formData1.append("seat_booked", formData.value.seat_booked);
            formData1.append("start_date", moment(formData.value.start_date).format("YYYY-MM-DD"));
            formData1.append("end_date", moment(formData.value.end_date).format("YYYY-MM-DD"));
            formData1.append("day", formData.value.day);
            formData1.append("night", formData.value.night);
            formData1.append("meals", formData.value.meals);
            formData1.append("tshirt", formData.value.tshirt);
            for(let i=0; i<formData.value.places.length; i++){
            formData1.append("places[]", formData.value.places[i]);}
            formData1.append("type_id", formData.value.type_id);
            formData1.append("category_id", formData.value.category_id);
            if(formData.value.description)formData1.append("description", formData.value.description);
            formData1.append("booking_deadline", moment(formData.value.booking_deadline).format("YYYY-MM-DD"));
            formData1.append("cancellation_deadline", moment(formData.value.cancellation_deadline).format("YYYY-MM-DD"));
            formData1.append("refund_percentage", formData.value.refund_percentage);
            formData1.append("price_adults", formData.value.price_adults);
            formData1.append("discounted_price_adults", formData.value.discount_adults);
            formData1.append("price_children", formData.value.price_children);
            formData1.append("discounted_price_children", formData.value.discount_children);
            if(formData.value.tour_policy)formData1.append("tour_policy", formData.value.tour_policy);
            if(formData.value.cancellation_policy)formData1.append("cancellation_policy", formData.value.cancellation_policy);
            if(formData.value.children_policy)formData1.append("children_policy", formData.value.children_policy);
            if (formData.value.included) {
                for(let i=0; i<formData.value.included.length; i++){
                    formData1.append("included[]", formData.value.included[i]);}
            }
            if (formData.value.excluded) {
                for(let i=0; i<formData.value.excluded.length; i++){
                formData1.append("excluded[]", formData.value.excluded[i]);}
            }
            if (formData.value.prerequisites) {
                for(let i=0; i<formData.value.prerequisites.length; i++){
                formData1.append("prerequisites[]", formData.value.prerequisites[i]);}
            }
            formData1.append("starting_point[line]", formData.value.starting_point.line);
            formData1.append("starting_point[thana]", formData.value.starting_point.thana);
            formData1.append("starting_point[district]", formData.value.starting_point.district);
            formData1.append("starting_point[division]", formData.value.starting_point.division);
            formData1.append("starting_point[country]", formData.value.starting_point.country);
            formData1.append("destination[line]", formData.value.destination.line);
            formData1.append("destination[thana]", formData.value.destination.thana);
            formData1.append("destination[district]", formData.value.destination.district);
            formData1.append("destination[division]", formData.value.destination.division);
            formData1.append("destination[country]", formData.value.destination.country);
            for (let i=0; i<formData.value.schedule.length; i++){
                formData1.append('schedule['+i+'][day]', formData.value.schedule[i].day);
                formData1.append('schedule['+i+'][name]', formData.value.schedule[i].name);
                for (let j=0; j<formData.value.schedule[i].activities.length; j++){
                    formData1.append('schedule['+i+'][activities]['+j+'][time]',  moment(formData.value.schedule[i].activities[j].time).format("hh:mm a"));
                    formData1.append('schedule['+i+'][activities]['+j+'][title]', formData.value.schedule[i].activities[j].title);
                    formData1.append('schedule['+i+'][activities]['+j+'][description]', formData.value.schedule[i].activities[j].description);
                }
            }
            formData1.append("agency_id", formData.value.agency_id);
            formData1.append("manager_id", formData.value.manager_id);
            Axios
            .post("api/admin/tour/create", formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
                    router.replace({name: 'details', params:{id: res.data.data.id, slug: res.data.data.slug}})
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
                loading.value = false;
                showToast("error", "Error", "Something went wrong. Try again!");
            });
        }

        return{
            start_date,
            end_date,
            booking_deadline,
            cancellation_deadline,
            formatDate,
            formatTime,
            complete,
            loading
        }
    },
    methods: {
        prevPage() {
            this.$emit('prev-page', {pageIndex: 4});
        }
    }
}
</script>

<style>
    img{
        border: 1px solid #007be5;
    }
</style>